import { RouteType } from "./auth-routes";

//pages
import Dashboard from "../pages/admin/dashboard";

// import NotFoundPage from "../pages/Not-found";

const privateRoutes: RouteType[] = [
    {
        path: '/app/dashboard',
        component:<Dashboard />
    },
    
    // {
    //     path: '*',
    //     component: <NotFoundPage/>
    // },
    
];

export default privateRoutes;