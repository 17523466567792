import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Navbar from '../Navbar';

import vector1 from '../../../assets/imgs/hero-vector-1.png';
import vector2 from '../../../assets/imgs/hero-vector-2.png';
import carImg from '../../../assets/imgs/white-car.png';
import foodPlateImg from '../../../assets/imgs/fries-plate.png';
import shopingCartImg from '../../../assets/imgs/shopping-cart.png';
import payBillsImg from '../../../assets/imgs/bank-card.png';
import playStoreIcon from '../../../assets/icons/playstore-icon.svg';
import appStoreIcon from '../../../assets/icons/apple-icon.svg';
import floatingText from '../../../assets/icons/text.svg';
import groupAvatar from '../../../assets/imgs/avatar-group.png';

interface IHeroCard {
    title: string;
    img: string;
    color?: string;
}

type Props = {
    data: IHeroCard
}


const HeroCard: FC<Props> = ({ data }) => {
    return (
        //Go anywhere
        <>
            <div 
                style={{ backgroundColor: data.color || '#F4E7FF'}} 
                className="rounded-lg relative min-h-[250px] w-[100%] md:w-[75%] lg:w-[65%] pt-8">
                <div className="flex justify-center">
                    <p className="text-[#121212CC] text-xl">{ data.title }</p>
                </div>

                <div className="absolute -bottom-12 left-8">
                    <img 
                        src={data.img} 
                        alt="hero-card-img" 
                        className="w-[300px] h-[300px] object-contain" 
                    />
                </div>
            </div>
        </>
    )
}

const HeroCards: IHeroCard[] = [
    {
        title: 'Go anywhere',
        img: carImg,
        color: '#F4E7FF'
    },
    {
        title: 'Eat anywhere',
        img: foodPlateImg,
        color: '#FCF7C5'
    },
    {
        title: 'Shop anywhere',
        img: shopingCartImg,
        color: '#FCF7C5'
    },
    {
        title: 'Pay any bills',
        img: payBillsImg,
        color: '#F4E7FF'
    },
]


const HeroComp: FC = () => {
    const [currentIndx, setCurrentIndex] = useState<number>(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prev => {
                console.log('prev', prev ++);
                let num: number = prev ++;
                if(num >= HeroCards.length){
                    num = 0
                }
                return num;
            });
        }, 3000)

        return () => {
            clearInterval(interval);
        }
    }, [])
    return (
        <>
            <div className="bg-[#831BD5] py-8 relative min-h-[600px] overflow-hidden">
                <img src={vector2} alt="vector2" className="absolute -top-3 -right-8" />
                <img src={vector2} alt="vector2" className="absolute -top-2 -right-4" />
                <img src={vector1} alt="vector1" className="absolute -bottom-8 left-0" />
                <img src={vector1} alt="vector1" className="absolute -bottom-4 left-0" />

                <div className="mx-auto w-11/12 sm:w-11/12 md:w-10/12 lg:10/12">
                    <Navbar />

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-8 mt-16">
                        <div className="">
                            <h1 className="text-white text-4xl sm:text-5xl  md:text-5xl lg:text-6xl font-semibold">Your Everyday Needs, Simplified</h1>

                            <div className="my-12 flex justify-start gap-4">
                                <div>
                                    <img src={groupAvatar} alt="group avatar" />
                                </div>
                                <div>
                                    <p className="text-white text-xs md:text-sm lg:text-sm">
                                    Join with 1k+ Users and enjoy rides, food, groceries and pay bills in just a click
                                    </p>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4 mt-8">
                                <div>
                                    <Button
                                         sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem',
                                        }}
                                        startIcon={
                                            <img src={appStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on apple store</span>
                                    </Button>
                                </div>
                                <div>
                                    <Button
                                        sx={{
                                            width: '80%',
                                            fontSize: '11px'
                                        }}
                                        className="relative"
                                        variant={'contained'}
                                        color={'primary'}
                                        style={{
                                            backgroundColor: '#5C188B',
                                            color: '#ffffff',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'start',
                                            gap: 4,
                                            padding: '1rem'
                                        }}
                                        startIcon={
                                            <img src={playStoreIcon} alt="playstor-icon" />
                                        }
                                    >
                                        <span>Download on play store</span>
                                    <img src={floatingText} alt="floatingText" className="absolute w-[60px] md:w-[90px] -right-16 md:-right-24" />
                                    </Button>

                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center mt-12 md:mt-0 lg:mt-0">
                            <HeroCard 
                                data={HeroCards[currentIndx]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default HeroComp;