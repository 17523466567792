import { FC, ReactNode, useState, useEffect } from "react";

export type Tab = {
    children: ReactNode;
    title: string;
}

type Props = {
    variant?: "pill" | 'underline'
    tabs: Tab[];
    tabIndex?: number
}

const AppTab: FC<Props> = ({ tabs, variant = 'pill', tabIndex = 0 }) => {
    const [activeTab, setActiveTab] = useState<Tab | null>( null );

    useEffect(() => {
        if(tabs.length > 0){
            setActiveTab(tabs[tabIndex])
        }
    }, [variant, tabs, tabIndex]);


    return tabs.length <= 0 ? (null) : (
        <>
            <div className="my-4 w-full">
                <div className="flex justify-start gap-2 mb-8">
                    {
                        tabs.map((tab: Tab, idx: number) => (
                            <div key={idx}>
                                <div 
                                    className={`w-max px-4 py-1 text-center rounded-[30px] text-[12px] cursor-pointer
                                        ${ 
                                            ((variant === 'pill') && (activeTab?.title === tab.title)) 
                                                ? 'bg-purpleAlert text-primary'
                                                : ((variant === 'underline') && (activeTab?.title === tab.title))
                                                ? 'underline text-primary'
                                                : 'text-darkGray'
                                        }`}
                                    onClick={() => setActiveTab(tab)}
                                >{tab.title}</div>
                            </div>
                        ))
                    }
                    
                </div>

                <div className="mt-8 mb-2">
                    { activeTab && activeTab.children }
                </div>
            </div>
        </>
    )
}

export default AppTab;