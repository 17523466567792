import { FC, useState } from "react";
import { IoIosArrowDown } from "react-icons/io";



import questionSvg from '../../../assets/icons/questionMark.svg';
import { Faq, FaqOption, Faqs } from '../../../constants';

const FaqComp: FC = () => {

    const [activeFaq, setActiveFaq] = useState<Faq | null>(Faqs[0]);

    return (
        <>
            <div className="w-full bg-[#F4E7FF33] border-[1px] border-[#C0C5C2] rounded-md py-8 min-h-[400px]">
                <div className="flex flex-row gap-4">
                    <div className="w-2/6">
                        <div className="w-10/12 px-auto mx-auto">
                            <p className="font-semibold">Table of content</p>

                            <div className="my-8 mx-auto">
                                {
                                    Faqs.map((faq: Faq, idx: number) => (
                                        <div 
                                            key={idx} 
                                            className={`hover:bg-[#831BD5] hover:text-white my-4 w-max ${ activeFaq && (activeFaq.category === faq.category) ? 'bg-[#831BD5] text-white' : 'text-[#12121280]'} rounded-md px-8 py-4`}
                                            onClick={() => setActiveFaq(faq)}
                                        >{faq.category}</div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="flex-grow px-4">
                        {
                            activeFaq && activeFaq.items.map((item: FaqOption, idex: number) => {
                                return (
                                    <div className="w-full bg-[#D9D9D980] px-6 py-4 my-4 rounded-lg">
                                        <div className="flex justify-between">
                                            <div className="flex justify-start gap-2">
                                                <div className="my-auto">
                                                    <img src={questionSvg} width={20}  alt="questionSvg" className="my-auto" />
                                                </div>
                                                <div>
                                                    <p className="text-[#121212CC] text-xl">{ item?.question }</p>
                                                </div>
                                            </div>

                                            <div>
                                                <IoIosArrowDown size={20} className='text-[#121212CC]' />
                                            </div>
                                        </div>

                                        <div className="bg-[#FEF9F9] p-4 my-4">
                                            <p className="text-[#121212CC]">
                                                { item?.answer }
                                            </p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default FaqComp;