import { ReactNode } from "react";
import HomePage from "../pages/website/home-page";
import Dashboard from "../pages/admin/dashboard";
import LoginPage from "../pages/Auth/Login-page";
import ForgotPasswordPage from "../pages/Auth/ForgotPassword";
import UserDashboard from "../pages/admin/users/UserDashbaord";
import CreateEditUserPage from "../pages/admin/users/CreateEditUserPage";
import RidersDashboard from "../pages/admin/riders/RidersDashboard";
import VendorsDashbaord from "../pages/admin/vendors/VendorsDashboard";
import BillsPages from "../pages/admin/Bills";




export type RouteType = {
    path: string;
    component: ReactNode
}

const authRoutes: RouteType[] = [
    {
        path: '/',
        component: <HomePage />,
    },
    {
        path: '/admin/dashboard',
        component:<Dashboard />
    },
    {
        path: '/admin/users',
        component:<UserDashboard />
    },
    {
        path: '/admin/users/create',
        component:<CreateEditUserPage />
    },
    {
        path: '/admin/riders',
        component:<RidersDashboard />
    },
    {
        path: '/admin/vendors',
        component:<VendorsDashbaord />
    },
    {
        path: '/admin/bills-payment',
        component:<BillsPages />
    },
    
    // {
    //     path: '/sign-up',
    //     component: <SignUP/>
    // },
    {
        path: '/admin/login',
        component: <LoginPage/>
    },
    // {
    //     path: '/verify',
    //     component: <EmailVerificationPage />
    // },
    // {
    //     path: '/verify/:code',
    //     component: <EmailVerificationPage />
    // },
    {
      path: '/admin/forgot-password',
      component:<ForgotPasswordPage />
    },

    // {
    //     path: '*',
    //     component: <NotFoundPage/>
    // }
]

export default authRoutes;